interface IntersectionObserverConfig {
  root: HTMLElement|null
  rootMargin: string
  threshold: number[]|number
}

export const DOWN = 'DOWN'
export const UP = 'UP'

export const IN = 'IN'
export const OUT = 'OUT'

export const ABOVE = 'ABOVE'
export const BENEATH = 'BENEATH'

const scrollObserver = (callback :any, config?: IntersectionObserverConfig, idx? :number) => {
  let wasIntersecting: boolean
  let previousY: number
  let previousIntersectionRatio: number

  return new IntersectionObserver((entries) => {
    let scrollDirection: string
    let moving: string
    let currentY: number
    let relativeToViewPort: string

    entries.forEach((entry) => {
      const { isIntersecting, intersectionRatio } = entry;
      if (callback) {
        /* eslint-disable max-len */
        const isFirstRenderOnPageLoadInsideViewPort = intersectionRatio > 0 && (isIntersecting !== wasIntersecting)
        const isFirstRenderOnPageLoadOutsideViewPort = !isIntersecting && (isIntersecting !== wasIntersecting)
        /* eslint-enable max-len */
        if (!isFirstRenderOnPageLoadInsideViewPort) {
          const EntryBoundingClientRect = entry.boundingClientRect

          currentY = EntryBoundingClientRect.y
          scrollDirection = currentY < previousY ? DOWN : UP
          if (isIntersecting) {
            moving = intersectionRatio > previousIntersectionRatio ? IN : OUT
          } else {
            // eslint-disable-next-line max-len
            relativeToViewPort = (EntryBoundingClientRect.height + EntryBoundingClientRect.top) > 0 ? BENEATH : ABOVE
          }
        }

        callback({
          isIntersecting,
          intersectionRatio,
          isFirstRenderOnPageLoad: isFirstRenderOnPageLoadInsideViewPort,
          isFirstRenderOutOfViewPortOnPageLoad: isFirstRenderOnPageLoadOutsideViewPort,
          relativeToViewPort,
          scrollDirection,
          moving,
        }, idx)

        wasIntersecting = isIntersecting
        previousY = currentY
        previousIntersectionRatio = intersectionRatio
      }
    })
  }, config)
}

export interface InterSectionObserverCallbackParams {
  isIntersecting: boolean,
  intersectionRatio: number,
  isFirstRenderOnPageLoad: boolean,
  isFirstRenderOutOfViewPortOnPageLoad: boolean,
  relativeToViewPort: string,
  scrollDirection: string,
  moving: string,
}

export { scrollObserver }
