import React, { useCallback, useEffect, useState } from 'react'
import { animated } from 'react-spring'
import {
  fadeUpSpring,
  IndexPageAnimationsStateUpdateCallback,
  SIOUnSubscriber,
} from './IndexPageAnimations'

const ScrollFadeUpAnimation = (
  { element = null, children, animationWrapperClass }:
  { element?: HTMLElement|null, children: any, animationWrapperClass?: string },
) => {
  const [fadeUpAnimationsOn, toggleSection] = useState(true);
  const springIndexSection = fadeUpSpring(fadeUpAnimationsOn)

  // eslint-disable-next-line max-len
  const ToggleIndexPageSectionsAnimations = IndexPageAnimationsStateUpdateCallback({
    toggler: toggleSection,
  })

  SIOUnSubscriber({
    useEffect,
    toggleCallback: ToggleIndexPageSectionsAnimations,
    element,
  })

  return (
    <animated.div style={{ ...springIndexSection, width: '100%' }} className={animationWrapperClass}>
      {children}
    </animated.div>
  )
}

export function fadeUpWrapper(Component:any) {
  return ({ ...props }:any) => {
    const [ref, setRef] = useState<HTMLElement|null>(null)
    const setRefCallback = useCallback((node:HTMLElement) => {
      setRef(node)
    }, [])
    return (
      <ScrollFadeUpAnimation element={ref}>
        <Component ref={setRefCallback} {...props} />
      </ScrollFadeUpAnimation>
    )
  }
}

ScrollFadeUpAnimation.defaultProps = {
  animationWrapperClass: '',
  element: null,
}

export default ScrollFadeUpAnimation
