export interface AnimationProps {
  from: object
  to: object
  delay?: number
  velocity?: number
  duration?: number
}

const ANIMATION_SHIFT_Y = 20
export const TRANSITION_DURATION = 150
export const POPUP_TRANSITION_DURATION = 50

export const fadeUpAnimation = {
  from: {
    opacity: 0,
    transition: `all ${TRANSITION_DURATION}ms ease`,
    transform: `translate3d(0, ${ANIMATION_SHIFT_Y}px, 0)`,
  },
  to: {
    transition: `all ${TRANSITION_DURATION}ms ease`,
    opacity: 1,
    transform: 'translate3d(0, 0px, 0)',
  },
}

export const fadeDownAnimation = {
  from: {
    opacity: 1,
    transition: `all ${TRANSITION_DURATION}ms ease`,
    transform: 'translate3d(0, 0px, 0)',
  },
  to: {
    transition: `all ${TRANSITION_DURATION}ms ease`,
    opacity: 0,
    transform: `translate3d(0, ${ANIMATION_SHIFT_Y}px, 0)`,
  },
}

export const popUpFadeUpDownTransition = {
  from: {
    opacity: 0,
    transition: `all ${POPUP_TRANSITION_DURATION}ms ease`,
    transform: `translate3d(0, ${ANIMATION_SHIFT_Y}px, 0)`,
  },
  enter: {
    transition: `transform ${POPUP_TRANSITION_DURATION}ms ease, opacity ${POPUP_TRANSITION_DURATION}ms ease-out`,
    opacity: 1,
    transform: 'translate3d(0, 0px, 0)',
  },
  leave: {
    opacity: 0,
    transform: `translate3d(0, ${ANIMATION_SHIFT_Y}px, 0)`,
  },
}
