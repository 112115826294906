
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React, { useEffect, useState } from 'react'
import { GA } from "./src/data/GA"
// General layout wrapper for pages which is not supposed to rerender during navigation
const Wrapper = ({ element, properties }) => {
  const [firstLoadSetting, setFirstLoadSetting] = useState(false)
  useEffect(() => {
    if (!firstLoadSetting) {
      GA.rockerbox()
      setFirstLoadSetting(true)
    }
  }, []);

  let Layout
  let props = {}
  if (element.type.Layout) {
    Layout = element.type.Layout
    props = properties
  } else {
    Layout = React.Fragment
  }
  return <Layout {...props}>{element}</Layout>
}
export function wrapPageElement({ element, properties }) {
  return <Wrapper element={element} properties={properties} />

}
