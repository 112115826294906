export const size = {
  a: '540px',
  b: '770px',
  c: '950px',
  d: '1290px',
  e: '1440px',
}

export const from = {
  bpA: `(min-width: ${size.a})`,
  bpB: `(min-width: ${size.b})`,
  bpC: `(min-width: ${size.c})`,
  bpD: `(min-width: ${size.d})`,
  bpE: `(min-width: ${size.e})`,
};

export const to = {
  bpA: `(max-width: ${size.a})`,
  bpB: `(max-width: ${size.b})`,
  bpC: `(max-width: ${size.c})`,
  bpD: `(max-width: ${size.d})`,
  bpE: `(max-width: ${size.e})`,
};

export const MOBILE_HEADER_HEIGHT = '60px';
