import { useSpring } from 'react-spring'

import {
  IN,
  UP,
  DOWN,
  ABOVE,
  BENEATH,
  InterSectionObserverCallbackParams,
  scrollObserver,
} from '../lib/ScrollObserver'
import { fadeUpAnimation } from './common'

export const fadeUpSpring = (animationOn: boolean) => useSpring(
  animationOn
    ? {
      from: fadeUpAnimation.from,
      to: fadeUpAnimation.to,
    }
    : {
      from: fadeUpAnimation.to,
      to: fadeUpAnimation.from,
    },
)

// SIO stands for Scroll Intersection Observer
export const IndexPageSectionsSIOConfig = {
  root: null, // setting root to null sets it to viewport
  rootMargin: '0px',
  threshold: [0, 0.05, 0.4],
}

export const IndexPageAnimationsStateUpdateCallback = ({
  toggler,
}: {
  toggler: any
}) => ({
  intersectionRatio,
  isFirstRenderOnPageLoad,
  isFirstRenderOutOfViewPortOnPageLoad,
  scrollDirection,
  moving,
  relativeToViewPort,
}: InterSectionObserverCallbackParams) => {
  const RoundedRatio = Math.round(intersectionRatio * 100) / 100
  // eslint-disable-next-line max-len
  const ENTERING_VIEWPORT = scrollDirection === DOWN
    && intersectionRatio > 0
    && intersectionRatio < 0.4
    && moving === IN
  const LEFT_VIEWPORT_VIA_BOTTOM = scrollDirection === UP
    && RoundedRatio === 0
    && typeof moving === 'undefined'

  // eslint-disable-next-line max-len
  if (
    ENTERING_VIEWPORT
    || isFirstRenderOnPageLoad
    || (isFirstRenderOutOfViewPortOnPageLoad && relativeToViewPort === ABOVE)
  ) {
    return toggler(true)
  }

  // eslint-disable-next-line max-len
  if (
    LEFT_VIEWPORT_VIA_BOTTOM
    || (isFirstRenderOutOfViewPortOnPageLoad && relativeToViewPort === BENEATH)
  ) {
    return toggler(false)
  }

  return null
}

export const SIOUnSubscriber = ({
  useEffect,
  toggleCallback,
  element,
}: {
  useEffect: any
  toggleCallback: any
  element: HTMLElement | null
}) => useEffect(() => {
  if (!element) {
    return () => {}
  }
  const IndexPageObserver = scrollObserver(
    toggleCallback,
    IndexPageSectionsSIOConfig,
  )

  IndexPageObserver.observe(element)

  return function cleanup() {
    IndexPageObserver.unobserve(element)
  }
}, [element])
