// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blings-tsx": () => import("./../../../src/pages/blings.tsx" /* webpackChunkName: "component---src-pages-blings-tsx" */),
  "component---src-pages-blingsplayer-tsx": () => import("./../../../src/pages/blingsplayer.tsx" /* webpackChunkName: "component---src-pages-blingsplayer-tsx" */),
  "component---src-pages-faqs-auction-tsx": () => import("./../../../src/pages/faqs/auction.tsx" /* webpackChunkName: "component---src-pages-faqs-auction-tsx" */),
  "component---src-pages-faqs-general-tsx": () => import("./../../../src/pages/faqs/general.tsx" /* webpackChunkName: "component---src-pages-faqs-general-tsx" */),
  "component---src-pages-faqs-getting-paid-tsx": () => import("./../../../src/pages/faqs/getting-paid.tsx" /* webpackChunkName: "component---src-pages-faqs-getting-paid-tsx" */),
  "component---src-pages-faqs-grading-tsx": () => import("./../../../src/pages/faqs/grading.tsx" /* webpackChunkName: "component---src-pages-faqs-grading-tsx" */),
  "component---src-pages-faqs-insurance-tsx": () => import("./../../../src/pages/faqs/insurance.tsx" /* webpackChunkName: "component---src-pages-faqs-insurance-tsx" */),
  "component---src-pages-faqs-pricing-tsx": () => import("./../../../src/pages/faqs/pricing.tsx" /* webpackChunkName: "component---src-pages-faqs-pricing-tsx" */),
  "component---src-pages-faqs-service-costs-tsx": () => import("./../../../src/pages/faqs/service-costs.tsx" /* webpackChunkName: "component---src-pages-faqs-service-costs-tsx" */),
  "component---src-pages-faqs-shipping-tsx": () => import("./../../../src/pages/faqs/shipping.tsx" /* webpackChunkName: "component---src-pages-faqs-shipping-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */)
}

