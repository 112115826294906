import { links } from '../components/homepage/submissionSelectionSection/submissionSelectionHelper'
import { INFO_WORTY_EMAIL, WORTHY_PHONE } from '../static-content/constants'
import { BaseEvent, callbackObj, pushToDataLayer } from './events'
import { envUrls } from './objects/env'

const labels = {
  header: 'Header',
  footer: 'Footer',
  aboveTheFold: 'Above fold',
  hamburger: 'Hamburger menu',
  recommended: 'Recommended for you',
  process: 'Our process',
  youtube: 'Youtube',
  contactUs: 'Contact us',
  expertReview: 'Expert review',
  storyReview: 'Story review',
  videoReview: 'Video review',
  faqPage: 'FAQ page',
  faqs: 'FAQs',
  soldItemCarousel: 'Sold items carousel',
  testimonialsCarousel: 'Testimonials carousel',
}

const categories = {
  HP: 'Homepage',
  reviewsPage: 'Reviews page',
  faqPage: 'FAQs page',
  HIWPage: 'How it Works page',
}

const DOMAIN = 'content'

const PAGES = {
  reviews: 'reviews',
  HIW: 'howItWork',
  HP: 'home',
  FAQS: 'faqs',
}

const pageMapObject = {
  'FAQs page': PAGES.FAQS,
  'How it Works page': PAGES.HIW,
  Reviews: PAGES.reviews,
}
export class GA {
  static intercomData(data: {
    event: 'contactDataEvent'
    contactId: number
    contactName: string
    contactEmail: string
    contactPhone: string
    itemsCount: number
    postDeliveredItemsCount: number
    contactHashKey: string
    'User Account': string
    AccountManager: string
  }) {
    pushToDataLayer(data)
    BaseEvent.data({
      contactId: data.contactId,
      contactName: data.contactName,
      contactEmail: data.contactEmail,
      contactPhone: data.contactPhone,
      contactHashKey: data.contactHashKey,
      itemsCount: data.itemsCount,
      postDeliveredItemsCount: data.postDeliveredItemsCount,
      userAccount: data['User Account'],
      accountManager: data.AccountManager,
    })
  }

  static utmData(utmMedium: string | null) {
    const data = { utmMedium }
    pushToDataLayer(data)
    BaseEvent.data(data)
  }

  static rockerbox() {
    pushToDataLayer({ rockerboxEvent: 'rockerboxEvent' })
  }

  static testimonials(name: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.reviewsPage,
      GA_event_action: `${name} video clicked`,
      GA_event_label: labels.videoReview,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.reviews, 'videoTestimonials'], actionedObject: name })
  }

  static successStories(storyText: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.reviewsPage,
      GA_event_action: `${storyText} story clicked`,
      GA_event_label: labels.storyReview,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.reviews, 'successStories'], actionedObject: storyText })
  }

  static expertsReview(expertName: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.reviewsPage,
      GA_event_action: `${expertName} Expert review clicked`,
      GA_event_label: labels.expertReview,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.reviews, 'expertsReview'], actionedObject: expertName })
  }

  static FAQEvent(title: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.faqPage,
      GA_event_action: `${title} clicked`,
      GA_event_label: labels.faqPage,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.FAQS], actionedObject: title })
  }

  static processEvent(text: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HIWPage,
      GA_event_action: `${text} clicked`,
      GA_event_label: labels.process,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.HIW, 'processSteps'], actionedObject: text })
  }

  static youtubeEvent() {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HIWPage,
      GA_event_action: 'youtube clicked',
      GA_event_label: labels.youtube,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.HIW, 'youtubeVideo'] })
  }

  static aboveTheFoldEvent() {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: 'Edit item type clicked',
      GA_event_label: labels.aboveTheFold,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.HP, 'aboveFold'], actionedObject: 'itemTypeEdit' })
  }

  static submissionEvent(type: string, url: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: `Primary CTA clicked/${type}`,
      GA_event_label: labels.aboveTheFold,
      GA_event_value: null,
      ...callbackObj(url),
    })
    BaseEvent.select({
      location: [DOMAIN, PAGES.HP, 'aboveFold'],
      actionedObject: 'itemType',
      actionedValue: type,
      redirectTo: url,
    })
  }

  static HPTestimony(title: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: `${title} video clicked`,
      GA_event_label: labels.testimonialsCarousel,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.HP, 'videoTestimonials'], actionedObject: title })
  }

  static HPTestimonyScroll() {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: 'Scroll arrow clicked',
      GA_event_label: labels.testimonialsCarousel,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.HP, 'videoTestimonials'], actionedObject: 'scrollArrow' })
  }

  static mobileHeaderEvent(page: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: page,
      GA_event_action: 'Hamburger menu - clicked',
      GA_event_label: labels.hamburger,
      GA_event_value: null,
    })
  }

  static hamburgerMenuOpened() {
    BaseEvent.open({ location: [DOMAIN, 'header', 'hamburgerMenu'] })
  }

  static chatWithUs() {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: 'chat with us clicked',
      GA_event_label: labels.hamburger,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, 'header', 'hamburgerMenu', 'contact'], actionedObject: 'chat' })
  }

  static itemClickedHamburger(title: string, url?: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: `${title} clicked`,
      GA_event_label: labels.hamburger,
      GA_event_value: null,
      ...callbackObj(url),
    })
    BaseEvent.navigate({
      location: [DOMAIN, 'header', 'hamburgerMenu', 'linksList'],
      actionedObject: title,
      redirectTo: url,
    })
  }

  static emailUsHP() {
    const worthyEmail = `mailto:${INFO_WORTY_EMAIL}`
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: 'email us clicked',
      GA_event_label: labels.hamburger,
      GA_event_value: null,
      ...callbackObj(worthyEmail),
    })
    BaseEvent.navigate({
      location: [DOMAIN, 'header', 'hamburgerMenu', 'contact'],
      actionedObject: 'email',
      redirectTo: worthyEmail,
    })
  }

  static callUsHP() {
    const worthyPhone = `tel:+1 ${WORTHY_PHONE}`
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: 'call us clicked',
      GA_event_label: labels.hamburger,
      GA_event_value: null,
      ...callbackObj(worthyPhone),
    })
    BaseEvent.navigate({
      location: [DOMAIN, 'header', 'hamburgerMenu', 'contact'],
      actionedObject: 'phone',
      redirectTo: worthyPhone,
    })
  }

  static chatWithUsContact() {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HIWPage,
      GA_event_action: 'chat with us clicked',
      GA_event_label: labels.contactUs,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.HIW, 'contact'], actionedObject: 'chat' })
  }

  static callUs() {
    const worthyPhone = `tel:+1 ${WORTHY_PHONE}`
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HIWPage,
      GA_event_action: `call us clicked`,
      GA_event_label: labels.contactUs,
      GA_event_value: null,
      ...callbackObj(worthyPhone),
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.HIW, 'contact'], actionedObject: 'phone', redirectTo: worthyPhone })
  }

  static goToFAQs() {
    const url = '/faqs/'
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: 'Main CTA clicked (see more)',
      GA_event_label: labels.faqs,
      GA_event_value: null,
      ...callbackObj(url),
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.HP, 'faqs'], actionedObject: 'seeMore', redirectTo: url })
  }

  static HPFAQ(question: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: `${question} - Clicked`,
      GA_event_label: labels.faqs,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.HP, 'faqs'], actionedObject: question })
  }

  static soldItemsArrow() {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: 'Scroll arrow clicked',
      GA_event_label: labels.soldItemCarousel,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.HP, 'soldItems'], actionedObject: 'scrollArrow' })
  }

  static goToDealPage(itemType: string, url: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: `Item clicked ${itemType}`,
      GA_event_label: labels.soldItemCarousel,
      GA_event_value: null,
      ...callbackObj(url),
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.HP, 'soldItems'], actionedObject: itemType, redirectTo: url })
  }

  static goToRecentDeals() {
    const url = `${envUrls.site}/about/recent-deals/rings`
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: 'Section CTA clicked',
      GA_event_label: labels.soldItemCarousel,
      GA_event_value: null,
      ...callbackObj(url),
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.HP, 'soldItems'], actionedObject: 'seeMore', redirectTo: url })
  }

  static headerWithCallback(linkName: 'signin' | 'refer-friend', url: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: linkName,
      GA_event_label: labels.header,
      GA_event_value: null,
      ...callbackObj(url),
    })
    BaseEvent.navigate({ location: [DOMAIN, 'header', 'linksList'], actionedObject: linkName, redirectTo: url })
  }

  static headerCTA() {
    const url = links.submissionFlowRepositioning
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: 'Sticky header CTA - clicked',
      GA_event_label: labels.header,
      GA_event_value: null,
      ...callbackObj(links.submissionFlowRepositioning),
    })
    BaseEvent.navigate({ location: [DOMAIN, 'header'], actionedObject: 'CTA', redirectTo: url })
  }

  static headerPerPage(caption: string, page: string, url: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: page,
      GA_event_action: `${caption} – clicked`,
      GA_event_label: labels.header,
      GA_event_value: null,
      ...callbackObj(url),
    })
    BaseEvent.navigate({ location: [DOMAIN, 'header', 'linksList'], actionedObject: caption, redirectTo: url })
  }

  static headerLink(linkName: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: linkName,
      GA_event_label: labels.header,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, 'header', 'linksList'], actionedObject: linkName })
  }

  static footerWithCallback(title: string, url: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: `${title} Clicked`,
      GA_event_label: labels.footer,
      GA_event_value: null,
      ...callbackObj(url),
    })
    BaseEvent.navigate({ location: [DOMAIN, 'footer'], actionedObject: title, redirectTo: url })
  }

  static footerEvent(title: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HP,
      GA_event_action: `${title} Clicked`,
      GA_event_label: labels.footer,
      GA_event_value: null,
    })
    BaseEvent.navigate({ location: [DOMAIN, 'footer'], actionedObject: title })
  }

  static emailUsContact() {
    const worthyEmail = `mailto:${INFO_WORTY_EMAIL}`
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: categories.HIWPage,
      GA_event_action: 'email us clicked',
      GA_event_label: labels.contactUs,
      GA_event_value: null,
      ...callbackObj(worthyEmail),
    })
    BaseEvent.navigate({ location: [DOMAIN, PAGES.HIW, 'contact'], actionedObject: 'email', redirectTo: worthyEmail })
  }

  static recommendedLink(page: 'FAQs page' | 'How it Works page' | 'Reviews', label: string, url: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: page,
      GA_event_action: `${label} clicked`,
      GA_event_label: labels.recommended,
      GA_event_value: null,
      ...callbackObj(url),
    })
    BaseEvent.navigate({
      location: [DOMAIN, pageMapObject[page], 'recommendedForYou'],
      actionedObject: label,
      redirectTo: url,
    })
  }

  static recommendedCTA(page: 'FAQs page' | 'How it Works page' | 'Reviews') {
    const url = links.submissionFlowRepositioning
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_category: page,
      GA_event_action: 'Primary CTA clicked',
      GA_event_label: labels.recommended,
      GA_event_value: null,
      ...callbackObj(url),
    })
    BaseEvent.navigate({
      location: [DOMAIN, pageMapObject[page], 'recommendedForYou'],
      actionedObject: 'getStarted',
      redirectTo: url,
    })
  }
}
