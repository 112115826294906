import WorthySite from './WorthySite'

export interface Subsets {
  name: string,
  quantity: number
}

async function submitJB(selectedTypes: { source: string; subsets: Subsets[] }) {
  let res;
  try {
    res = await WorthySite.post('/api/v2/sellers/jewelry_box/create_jewelry_box', true, selectedTypes, {}, true)
  } catch (err) {
    res = err
    // TODO - change to to rollbar error
    console.log(err)
  }
  return res
}
export default {
  submitJB,
}
