const getSubDomainPrefix = () => window.location.host.split('.')[0]

const isReverseProxy = () => (['www', 'qa', 'demo', 'london', 'staging'].includes(getSubDomainPrefix()))

const getEnvName = () => {
  if (typeof window === 'undefined') return process.env.ENV
  const subDomainPrefix = getSubDomainPrefix()
  if (subDomainPrefix.includes('local')) return 'development'

  if (isReverseProxy()) {
    if (subDomainPrefix === 'www') return 'production'
    return subDomainPrefix
  }

  // if one of our online test environments
  if (subDomainPrefix.includes('-')) return subDomainPrefix.split('-')[1]
  return 'production'
}

export const envName = getEnvName()
export const isProduction = () => envName === 'production'

const getEnv = () => {
  // default to production: since we want our deployments to be environment agnostic,
  // we need to make sure that any pre-rendering errors will not cause production pages
  // to point to qa or any other env. That said, we should make sure that any links
  // we have are re-rendered once the client loads.
  const urls = {
    site: 'https://www.worthy.com',
    app: 'https://app.worthy.com',
    authenticator: 'https://auth.worthy.com',
    contentBE: 'https://contentbe.worthy.com',
    submissionFlowReposition: 'https://submit.worthy.com',
    marketplace: 'https://shop.worthy.com/',
  }
  if (typeof window === 'undefined') return urls

  const subDomainPrefix = getSubDomainPrefix()
  if (!isReverseProxy()) {
    urls.app = `https://${subDomainPrefix.replace('welcome', 'app')}.worthy.com`
    urls.authenticator = `https://${subDomainPrefix.replace('welcome', 'auth')}.worthy.com`
  }



  // our test environments include '-'
  if (subDomainPrefix.includes('-')) {
    urls.site = `https://${subDomainPrefix.replace('welcome-', '')}.worthy.com`
  } else if ((subDomainPrefix.includes('local')) || (process.env.LOCALHOST === 'true')) {
    // support our local developemnt
    urls.site = (process.env.LOCALHOST === 'true') ? 'http://localhost:3000' : 'http://local.worthy.com:3000'
    urls.authenticator = (process.env.LOCALHOST === 'true') ? 'http://localhost:6006' : `${urls.app.replace('https', 'http')}:6006`
    urls.app = (process.env.LOCALHOST === 'true') ? 'http://localhost:9001' : `${urls.app.replace('https', 'http')}:9001`
    urls.contentBE = (process.env.LOCALHOST === 'true') ? 'http://localhost:9020' : 'http://local.worthy.com:9020'
    urls.submissionFlowReposition = (process.env.LOCALHOST === 'true') ? 'http://localhost:8001' : 'http://localsubmit.worthy.com:8001'
  } else if (isReverseProxy()) {
    // if we're on the root domain of a test env.
    urls.site = `https://${subDomainPrefix}.worthy.com`
    if (!isProduction()) {
      urls.app = `https://app-${subDomainPrefix}.worthy.com`
      urls.authenticator = `https://auth-${subDomainPrefix}.worthy.com`
      urls.contentBE = `https://contentbe-${subDomainPrefix}.worthy.com`
      urls.submissionFlowReposition = `https://submit-${subDomainPrefix}.worthy.com`
    }
  }
  // support feature-links
  if (subDomainPrefix.includes('features')) {
    urls.site = process.env.SITE_URL!
    urls.app = process.env.APP_URL!
    urls.authenticator = process.env.APP_URL!.replace('app', 'auth')
    urls.contentBE = process.env.APP_URL!.replace('app', 'contentbe')
  }

  return urls
}

/**
 * Setting the environment keys only once. Once they're available they
 * will not change (this session)
 */
export const envUrls = getEnv()

export function replaceBuildTimeUrls(inUrl:string) {
  return inUrl
    .replace('#siteUrl#', envUrls.site)
    .replace('#appUrl#', envUrls.app)
    .replace('#marketplaceUrl#', envUrls.marketplace)
}
