import _ from 'lodash'

const { REACT_APP_GIT_HASH } = process.env
declare global {
  interface Window {
    dataLayer: any
  }
}

const EVENTS = {
  names: {
    interaction: 'interaction',
    data: 'pushData',
    feedback: 'feedback',
  },
  actions: {
    open: 'open',
    click: 'click',
    success: 'success',
    select: 'select',
    input: 'input',
    navigate: 'navigate',
    clear: 'clear',
    toggle: 'toggle',
    failure: 'failure',
    close: 'close',
  },
} as const

interface KeyVal {
  [key: string]: any
}

interface EventParams {
  event: typeof EVENTS.names[keyof typeof EVENTS.names]
  action: typeof EVENTS.actions[keyof typeof EVENTS.actions]
  location: string[]
  actionedObject?: string
  actionedValue?: any
  redirectTo?: string
}

const removeUndefined = (obj: KeyVal) => {
  // eslint-disable-next-line no-param-reassign
  Object.keys(obj).forEach(key => ([null, undefined].includes(obj[key]) ? delete obj[key] : {}))
  return obj
}

export const callbackObj = (url?: string) => {
  let obj = {}
  if (url) {
    obj = {
      eventCallback() {
        window.location.href = url
      },
      eventTimeout: 2000,
    }
  }
  return obj
}

export const pushToDataLayer = (data: any) => {
  if (_.isEmpty(data)) return

  if (!window.dataLayer) return

  window.dataLayer.push(removeUndefined(data))
}

export class BaseEvent {
  static pushToDataLayer = (data: Record<string, any>) => {
    if (_.isEmpty(data)) return

    if (!window.dataLayer) return

    window.dataLayer.push(data)
  }

  static locationObject = (location: string[]) => {
    const [location1 = null, location2 = null, location3 = null, location4 = null, location5 = null] = location
    return {
      location1,
      location2,
      location3,
      location4,
      location5,
    }
  }

  static eventObject = (params: EventParams) => ({
    event: params.event,
    action: params.action || null,
    actionedObject: params.actionedObject || null,
    actionedValue: params.actionedValue || null,
    appVersion: REACT_APP_GIT_HASH || null,
    ...this.locationObject(params.location),
    ...(params.redirectTo ? callbackObj(params.redirectTo) : {}),
  })

  static dataEventObject = (data: any) =>
    removeUndefined({
      ...data,
      event: EVENTS.names.data,
      appVersion: REACT_APP_GIT_HASH,
    })

  static data(data: Record<string, unknown>) {
    this.pushToDataLayer(this.dataEventObject(data))
  }

  static open(params: Pick<EventParams, 'location'>) {
    this.pushToDataLayer(this.eventObject({ event: EVENTS.names.feedback, action: EVENTS.actions.open, ...params }))
  }

  static click(params: Pick<EventParams, 'location' | 'actionedObject' | 'actionedValue'>) {
    this.pushToDataLayer(
      this.eventObject({
        event: EVENTS.names.interaction,
        action: EVENTS.actions.click,
        ...params,
      })
    )
  }

  static success(params: Pick<EventParams, 'location'>) {
    this.pushToDataLayer(this.eventObject({ event: EVENTS.names.feedback, action: EVENTS.actions.success, ...params }))
  }

  static select(params: Pick<EventParams, 'location' | 'actionedObject' | 'actionedValue' | 'redirectTo'>) {
    this.pushToDataLayer(
      this.eventObject({
        event: EVENTS.names.interaction,
        action: EVENTS.actions.select,
        ...params,
      })
    )
  }

  static navigate(params: Pick<EventParams, 'location' | 'actionedObject' | 'redirectTo'>) {
    this.pushToDataLayer(
      this.eventObject({ event: EVENTS.names.interaction, action: EVENTS.actions.navigate, ...params })
    )
  }

  static input(params: Pick<EventParams, 'location' | 'actionedObject' | 'actionedValue'>) {
    this.pushToDataLayer(
      this.eventObject({
        event: EVENTS.names.interaction,
        action: EVENTS.actions.input,
        ...params,
      })
    )
  }
}
