import Cookie from 'js-cookie'
import axios from 'axios'
import { envName, envUrls, isProduction } from '../objects/env'

export const jwtName = () => (isProduction() ? 'jwt' : `${envName}_jwt`)

export default class WorthySite {
  static headers(auth = false, headersIn:any = {}, withCredentials:boolean = false) {
    const headers:Record<string, string> = {}
    Object.keys(headersIn).forEach((h) => { headers[h] = headersIn[h] })
    if (auth) {
      const token = Cookie.get(jwtName())
      if (token) headers.userToken = token
    }
    return { headers, withCredentials }
  }

  static async get(path:string, auth = false) {
    return (await axios.get(`${envUrls.site}${path}`, this.headers(auth, {}, true))).data
  }

  // eslint-disable-next-line max-len
  static async post(path:string, auth = false, data:any = {}, headers:any = {}, withCredentials:boolean = false) {
    return (await axios.post(`${envUrls.site}${path}`, data, this.headers(auth, headers, withCredentials))).data
  }

  static async delete(path:string, auth = false, data:any = {}, headers:any = {}) {
    return (await axios.delete(`${envUrls.site}${path}`, { ...this.headers(auth, headers), data })).data
  }

  static async hasCredentials() {
    const jwt = Cookie.get(jwtName())
    if (!jwt) {
      return false
    }
    return true
  }
}
