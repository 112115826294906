import React from 'react'
import styled from 'styled-components'
import Cookie from 'js-cookie'
import JewelryBox, { Subsets } from '../../../data/api/jewelryBox'
import { fadeUpWrapper } from '../../../animations/ScrollFadeUpAnimation'
import { envUrls } from '../../../data/objects/env'
import { to } from '../../../styles/constants'

// EXP WORTHY-12461
import ring from '../../../images/icons/exp/ring.svg'
import watch from '../../../images/icons/exp/watch.svg'
import necklace from '../../../images/icons/exp/necklace.svg'
import bracelet from '../../../images/icons/exp/bracelet.svg'
import earrings from '../../../images/icons/exp/earrings.svg'
import { GA } from '../../../data/GA'

interface Option {
  name: string
  value: string
  icon?: string
}

export interface WrapperProps {
  onClose: () => void
  options: Option[]
  // eslint-disable-next-line max-len
  checkBoxChangeEvent: (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedTypes: string[],
    setSelectedTypes: any
  ) => void
  handleSubmitClick: (selectedTypes: string[], isLoggedIn: boolean) => void
}

export const options: Option[] = [
  { name: 'Ring / ring set', value: 'ring', icon: ring },
  { name: 'Necklace', value: 'necklace', icon: necklace },
  { name: 'Bracelet', value: 'bracelet', icon: bracelet },
  { name: 'Loose diamond', value: 'diamond' },
  { name: 'Earrings', value: 'earrings', icon: earrings },
  { name: 'Luxury watch', value: 'watch', icon: watch },
]

const filteredOptions: Option[] = options.filter(i => !!i.icon)

export const optionsForExp: Option[] = filteredOptions.concat(filteredOptions[0]).reverse()

export const optionsForAnimation: Option[] = filteredOptions.concat(filteredOptions[0]).reverse()

export interface LinksObject {
  ringApp: string
  submissionFlowRepositioning: string
  ring: string
  necklace: string
  bracelet: string
  diamond: string
  earrings: string
  watch: string
  register: string
  jbAppPage: string
}

export const links: LinksObject = {
  ringApp: `${envUrls.app}`,
  submissionFlowRepositioning: `${envUrls.submissionFlowReposition}`,
  ring: `${envUrls.submissionFlowReposition}/ring`,
  necklace: `${envUrls.submissionFlowReposition}/necklace`,
  bracelet: `${envUrls.app}/submit/bracelet`,
  diamond: `${envUrls.submissionFlowReposition}/loose_diamond`,
  earrings: `${envUrls.submissionFlowReposition}/earrings`,
  watch: `${envUrls.app}/submit/watch`,
  register: `${envUrls.app}/register`,
  jbAppPage: `${envUrls.app}/multiple_jewelry`,
}

// eslint-disable-next-line max-len
export const checkBoxChangeEvent = (
  event: React.ChangeEvent<HTMLInputElement>,
  selectedTypes: string[],
  setSelectedTypes: (selectedTypes: string[]) => void
) => {
  const itemType = event.target.name
  if (event.target.checked) {
    selectedTypes.push(itemType)
  } else {
    const index = selectedTypes.indexOf(itemType)
    if (index > -1) {
      selectedTypes.splice(index, 1)
    }
  }
  setSelectedTypes([...selectedTypes])
}

export const SubmissionSelectionArea = fadeUpWrapper(styled.div`
  @media ${to.bpC} {
    max-width: 533px;
  }
`)

export const expSlugs = ['opt_submission_flow_reposition']

export const isCookieBasedExp = (slug: string): boolean => {
  const expCodeExists = expSlugs.includes(slug)
  if (!expCodeExists) {
    console.warn(`Such experiment not yet supported: ${slug}`)
    return false
  }
  if (Cookie.get(slug)) {
    return Cookie.get(slug) === 'true'
  }

  // @ts-ignore
  if (typeof window !== 'undefined' && window[slug]) {
    Cookie.set(slug, 'true', { domain: 'worthy.com', path: '/', expires: 30 })
    return true
  }
  return false
}

// eslint-disable-next-line max-len
export const handleSubmitClick = async (selectedTypes: string[], isLoggedIn: boolean) => {
  if (selectedTypes.length === 1) {
    const type = selectedTypes[0]
    GA.submissionEvent(type, links[type as keyof LinksObject])
  } else {
    const subsets: Subsets[] = []
    selectedTypes.map(type => subsets.push({ name: type, quantity: 1 }))
    const pendingItems = { source: 'hp-content-site', subsets }
    const res = await JewelryBox.submitJB(pendingItems)
    let redirectPath
    if (isLoggedIn) {
      redirectPath = `${links['jbAppPage' as keyof LinksObject]}/${res.bundle_id}`
    } else {
      redirectPath = `${links['register' as keyof LinksObject]}/?bundle=${res.bundle_id}`
    }
    GA.submissionEvent(selectedTypes.join('/'), redirectPath)
  }
}
